dmx.Component('geolocation', {

    initialData: {
        support: !!navigator.geolocation,
        status: navigator.geolocation ? 'Geolocation is not supported by this browser.' : 'Locating...',
        timestamp: null,
        coords: null // { latitude, longitude, altitude, accuracy, altitudeAccuracy, heading, speed }
    },

    attributes: {
        tracking: {
            type: Boolean,
            default: false
        },

        'enable-high-accuracy': {
            type: Boolean,
            default: false
        },

        'max-age': {
            type: Number,
            default: 30000
        },

        timeout: {
            type: Number,
            default: 27000
        }
    },

    methods: {
        getCurrentPosition: function() {
            this.getCurrentPosition();
        },

        watchPosition: function() {
            this.watchPosition();
        },

        clearWatch: function() {
            this.clearWatch();
        }
    },

    events: {
        success: Event,
        error: Event,
        permissiondenied: Event,
        unavailable: Event,
        timeout: Event
    },

    errorCodes: {
        1: 'permissiondenied',
        2: 'unavailable',
        3: 'timeout'
    },

    render: function(node) {
        this.update({});
    },

    update: function(props) {
        if (this.data.support && JSON.stringify(props) != JSON.stringify(this.props)) {
            if (this.props.tracking) {
                this.watchPosition();
            } else {
                this.clearWatch();
                this.getCurrentPosition();
            }
        }
    },

    getCurrentPosition: function() {
        if (this.data.support) {
            navigator.geolocation.getCurrentPosition(this.onSuccess.bind(this), this.onError.bind(this), {
                enableHighAccuracy: this.props['enable-high-accuracy'],
                timeout: this.props.timeout,
                maximumAge: this.props['max-age']
            });
        }
    },

    watchPosition: function() {
        if (this.data.support && !this.watching) {
            this.watching = navigator.geolocation.watchPosition(this.onSuccess.bind(this), this.onError.bind(this), {
                enableHighAccuracy: this.props['enable-high-accuracy'],
                timeout: this.props.timeout,
                maximumAge: this.props['max-age']
            });
        }
    },

    clearWatch: function() {
        if (this.data.support && this.watching) {
            navigator.geolocation.clearWatch(this.watching);
            delete this.watching;
        }
    },

    onSuccess: function(pos) {
        this.set('status', 'OK');
        this.set('timestamp', pos.timestamp);
        this.set('coords', {
            latitude         : pos.coords.latitude,
			longitude        : pos.coords.longitude,
			altitude         : pos.coords.altitude,
			accuracy         : pos.coords.accuracy,
			altitudeAccuracy : pos.coords.altitudeAccuracy,
			heading          : pos.coords.heading,
			speed            : pos.coords.speed
        });
        dmx.nextTick(this.dispatchEvent.bind(this, 'success'));
    },

    onError: function(err) {
        this.set('status', err.message || this.errorCodes[err.code] || 'Unknown Error.');
        dmx.nextTick(this.dispatchEvent.bind(this, this.errorCodes[err.code] || 'error'));
    }

});
